import React from "react";
import Layout from "../components/layout";
import ContactForm from "../components/contactForm";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import Reviews from "../components/reviews";
import { checkPropertiesForNull } from "../utils/objectHelper";
import { SafeHtmlParser } from "../components/safe-ntml-parser";

const ContactUsPage = () => {
  const data = useStaticQuery(graphql`
    query {
      wpOpeningTime(title: { eq: "Default Opening" }) {
        title
        openingTimeFields {
          mondaySaturday
          sunday
        }
      }
      site {
        siteMetadata {
          siteUrl
        }
      }
      pageData: wpPage(slug: { eq: "contact-us" }) {
        seoFieldGroups {
          metaTitle
          metaDescription
          localBusinessSchema
          openGraphDescription
          openGraphTitle
          productSchema
          image {
            node {
              altText
              publicUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    formats: [AUTO, WEBP]
                    quality: 100
                    transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                    layout: CONSTRAINED
                    placeholder: BLURRED
                  )
                  original {
                    width
                    height
                  }
                }
              }
            }
          }
        }
        contactUsPageFieldGroups {
          contactBannerSection {
            googleMap
            description
            heading
          }
          contactPageContactSection {
            contactForm {
              heading
              formBtnLabel
            }
            contactInformation {
              heading
              address {
                image {
                  node {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          formats: [AUTO, WEBP]
                          quality: 100
                          transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                          layout: CONSTRAINED
                          placeholder: BLURRED
                        )
                        original {
                          width
                          height
                        }
                      }
                    }
                  }
                }
                location {
                  title
                  target
                  url
                }
                address
              }
              email {
                image {
                  node {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          formats: [AUTO, WEBP]
                          quality: 100
                          transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                          layout: CONSTRAINED
                          placeholder: BLURRED
                        )
                        original {
                          width
                          height
                        }
                      }
                    }
                  }
                }
                email {
                  target
                  title
                  url
                }
              }
              openingHours {
                image {
                  node {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          formats: [AUTO, WEBP]
                          quality: 100
                          transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                          layout: CONSTRAINED
                          placeholder: BLURRED
                        )
                        original {
                          width
                          height
                        }
                      }
                    }
                  }
                }
              }
              phone1 {
                number {
                  target
                  title
                  url
                }
                image {
                  node {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          formats: [AUTO, WEBP]
                          quality: 100
                          transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                          layout: CONSTRAINED
                          placeholder: BLURRED
                        )
                        original {
                          width
                          height
                        }
                      }
                    }
                  }
                }
              }
              phone2 {
                number {
                  target
                  title
                  url
                }
                image {
                  node {
                    altText
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          formats: [AUTO, WEBP]
                          quality: 100
                          transformOptions: { cropFocus: CENTER, fit: CONTAIN }
                          layout: CONSTRAINED
                          placeholder: BLURRED
                        )
                        original {
                          width
                          height
                        }
                      }
                    }
                  }
                }
              }
              socialLinks {
                heading
                social {
                  image {
                    node {
                      altText
                      mediaItemUrl
                    }
                  }
                  url
                }
              }
            }
          }
          contactReviewsSection {
            heading
          }
        }
      }
    }
  `);

  const {
    pageData: { seoFieldGroups, contactUsPageFieldGroups },
  } = data;

  const {
    contactBannerSection,
    contactPageContactSection: { contactForm, contactInformation },
    contactReviewsSection,
  } = contactUsPageFieldGroups;

  const siteUrl = data.site.siteMetadata.siteUrl;

  const breadcrumb = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        name: "Home",
        item: {
          url: `${siteUrl}`,
          id: `${siteUrl}`,
        },
      },
      {
        "@type": "ListItem",
        position: "2",
        name: "Contact Us",
        item: {
          url: `${siteUrl}/contact-us`,
          id: `${siteUrl}/contact-us`,
        },
      },
    ],
  };

  return (
    <Layout>
      <Helmet>
        <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
      </Helmet>

      <GatsbySeo
        title={seoFieldGroups?.metaTitle}
        description={seoFieldGroups?.metaDescription}
        language="en"
        openGraph={{
          type: "website",
          url: `${siteUrl}/contact-us`,
          title: `${seoFieldGroups?.openGraphTitle}`,
          description: `${seoFieldGroups?.openGraphDescription}`,
          images: [
            {
              url: `${seoFieldGroups?.image?.node.localFile.publicURL}`,
              width: `${seoFieldGroups?.image?.node.localFile.childImageSharp.original.width}`,
              height: `${seoFieldGroups?.image?.node.localFile.childImageSharp.original.height}`,
              alt: `${seoFieldGroups?.image?.node.altText}`,
            },
          ],
        }}
      />

      {contactBannerSection &&
        !checkPropertiesForNull(contactBannerSection, ["heading"]) && (
          <section className="position-relative ">
            <Container className=" d-lg-none">
              <Row className="justify-content-center w-100">
                <Col lg={5}>
                  <div className="w-100 pt-3 pb-3 px-3 px-md-5 position-relative">
                    <h1
                      style={{ lineHeight: "130%" }}
                      className=" mb-3 mt-3  text-primary"
                    >
                      {contactBannerSection?.heading}
                    </h1>
                    <div className="mb-lg-0 text-black">
                      <SafeHtmlParser
                        htmlContent={contactBannerSection?.description}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
            <Container
              style={{ zIndex: 3 }}
              className="mt-lg-5 mt-7 position-absolute w-100 top-0 start-50 d-none d-lg-block  translate-middle-x"
            >
              <Row className="justify-content-center w-100">
                <Col lg={5}>
                  <div
                    className="w-100 pt-3 pb-3 px-3 px-md-5 position-relative"
                    style={{ background: "rgba(255, 255, 255,0.90)" }}
                  >
                    <h1
                      style={{ lineHeight: "130%" }}
                      className=" mb-3 mt-3  text-primary"
                    >
                      {contactBannerSection?.heading}
                    </h1>
                    <p className="mb-lg-0 text-black">
                      <SafeHtmlParser
                        htmlContent={contactBannerSection?.description}
                      />
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
            <iframe
              style={{ height: "80vh", border: "0", zIndex: 2 }}
              className="position-relative "
              src={contactBannerSection?.googleMap}
              width="100%"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </section>
        )}

      <section>
        <Container className="d-lg-none mt-4">
          <Row>
            <Col>
              <div
                style={{
                  zIndex: 4,
                }}
                className="position-relative  bg-white"
              >
                <Row>
                  {contactInformation &&
                    !checkPropertiesForNull(contactInformation, [
                      "heading",
                    ]) && (
                      <Col
                        className=" bg-light-grey py-5 px-md-5"
                        lg={{ span: 5, order: "last" }}
                      >
                        <h2 className="text-primary mb-5">
                          {contactInformation?.heading}
                        </h2>
                        <Row>
                          <Col>
                            <GatsbyImage
                              className=" d-inline-block me-3"
                              quality="100"
                              image={
                                contactInformation?.address.image.node
                                  ?.localFile.childImageSharp.gatsbyImageData
                              }
                              alt={
                                contactInformation?.address.image.node?.altText
                              }
                              placeholder="blurred"
                              style={{ maxWidth: "30px" }}
                              formats={["auto", "webp"]}
                            />

                            <a
                              className=" black-link pb-5 d-inline-block"
                              target={
                                contactInformation?.address.location?.target ??
                                "_blank"
                              }
                              rel="noreferrer"
                              href={contactInformation?.address.location?.url}
                            >
                              <SafeHtmlParser htmlContent={contactInformation?.address?.address} />
                            </a>
                          </Col>
                        </Row>
                        <Row className="mb-4">
                          <Col>
                            <GatsbyImage
                              className=" d-inline-block me-3"
                              quality="100"
                              image={
                                contactInformation?.phone1.image.node?.localFile
                                  .childImageSharp.gatsbyImageData
                              }
                              alt={
                                contactInformation?.phone1.image.node?.altText
                              }
                              placeholder="blurred"
                              style={{ maxWidth: "30px" }}
                              formats={["auto", "webp"]}
                            />

                            <a
                              className="d-inline-block black-link pb-2"
                              href={contactInformation?.phone1.number?.url}
                            >
                              {contactInformation?.phone1.number?.title}
                            </a>
                          </Col>
                        </Row>
                        <Row className="mb-4">
                          <Col>
                            <GatsbyImage
                              className=" d-inline-block me-3"
                              quality="100"
                              image={
                                contactInformation?.phone2.image.node?.localFile
                                  .childImageSharp.gatsbyImageData
                              }
                              alt={
                                contactInformation?.phone2.image.node?.altText
                              }
                              placeholder="blurred"
                              style={{ maxWidth: "30px" }}
                              formats={["auto", "webp"]}
                            />

                            <a
                              className="d-inline-block black-link pb-2"
                              href={contactInformation?.phone2.number?.url}
                            >
                              {contactInformation?.phone2.number?.title}
                            </a>
                          </Col>
                        </Row>
                        <Row className="mb-4">
                          <Col>
                            <GatsbyImage
                              className=" d-inline-block me-3"
                              quality="100"
                              image={
                                contactInformation?.openingHours.image.node
                                  ?.localFile.childImageSharp.gatsbyImageData
                              }
                              alt={
                                contactInformation?.openingHours.image.node
                                  ?.altText
                              }
                              placeholder="blurred"
                              style={{ maxWidth: "30px" }}
                              formats={["auto", "webp"]}
                            />
                            <p className=" d-inline-block py-0 m-0">
                              <span>Monday - Saturday</span>{" "}
                              {
                                data.wpOpeningTime.openingTimeFields
                                  .mondaySaturday
                              }
                              <br />
                              <span>Sunday</span>{" "}
                              {data.wpOpeningTime.openingTimeFields.sunday}
                            </p>{" "}
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <GatsbyImage
                              className=" d-inline-block me-3"
                              quality="100"
                              image={
                                contactInformation?.email.image.node?.localFile
                                  .childImageSharp.gatsbyImageData
                              }
                              alt={
                                contactInformation?.email.image.node?.altText
                              }
                              placeholder="blurred"
                              style={{ maxWidth: "30px" }}
                              formats={["auto", "webp"]}
                            />

                            <a
                              className="d-inline-block black-link pb-3"
                              href={contactInformation?.email.email?.url}
                            >
                              {contactInformation?.email.email?.title}
                            </a>
                          </Col>
                        </Row>
                        {contactInformation?.socialLinks &&
                          contactInformation?.socialLinks.social.length > 0 && (
                            <>
                              <h3 className="fs-4 text-primary mt-4">
                                {contactInformation?.heading}
                              </h3>
                              {contactInformation.socialLinks.social.map(
                                (item) => (
                                  <a
                                    className="primary-link me-3 fs-3 d-inline-block quicksand social-icon"
                                    href={item?.url}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <img src={item?.image.node?.mediaItemUrl} style={{ width: "28px" }}/>
                                  </a>
                                )
                              )}
                            </>
                          )}
                      </Col>
                    )}
                  <Col className=" py-5 px-md-5" lg={7}>
                    <h2 className="text-primary mb-5">
                      {contactForm?.heading ?? "Send us a message"}
                    </h2>
                    <ContactForm buttonLabel={contactForm?.formBtnLabel} />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
        <Container className="d-none d-lg-block">
          <Row>
            <Col>
              <div
                style={{
                  top: "-5rem",
                  boxShadow: "0px 3px 60px #00000058",
                  zIndex: 4,
                }}
                className="position-relative  bg-white"
              >
                <Row>
                  <Col
                    className="p-lg-5 p-3 bg-light-grey"
                    lg={{ span: 5, order: "last" }}
                  >
                    <h2 className="text-primary mb-5">
                      {contactInformation?.heading}
                    </h2>
                    <Row>
                      <Col>
                        <GatsbyImage
                          className=" d-inline-block me-3"
                          quality="100"
                          image={
                            contactInformation?.address.image.node?.localFile
                              .childImageSharp.gatsbyImageData
                          }
                          alt={contactInformation?.address.image.node?.altText}
                          placeholder="blurred"
                          style={{ maxWidth: "30px" }}
                          formats={["auto", "webp"]}
                        />

                        <a
                          className="black-link pb-5 d-inline-block"
                          target={
                            contactInformation?.address.location?.target ??
                            "_blank"
                          }
                          rel="noreferrer"
                          href={contactInformation?.address.location?.url}
                        >
                          <SafeHtmlParser
                            htmlContent={contactInformation?.address?.address}
                          />
                        </a>
                      </Col>
                    </Row>
                    <Row className="mb-4">
                      <Col>
                        <GatsbyImage
                          className=" d-inline-block me-3"
                          quality="100"
                          image={
                            contactInformation?.phone1.image.node?.localFile
                              .childImageSharp.gatsbyImageData
                          }
                          alt={contactInformation?.phone1.image.node?.altText}
                          placeholder="blurred"
                          style={{ maxWidth: "30px" }}
                          formats={["auto", "webp"]}
                        />

                        <a
                          className="d-inline-block black-link pb-2"
                          href={contactInformation?.phone1.number?.url}
                        >
                          {contactInformation?.phone1.number?.title}
                        </a>
                      </Col>
                    </Row>
                    <Row className="mb-4">
                      <Col>
                        <GatsbyImage
                          className=" d-inline-block me-3"
                          quality="100"
                          image={
                            contactInformation?.phone2.image.node?.localFile
                              .childImageSharp.gatsbyImageData
                          }
                          alt={contactInformation?.phone2.image.node?.altText}
                          placeholder="blurred"
                          style={{ maxWidth: "30px" }}
                          formats={["auto", "webp"]}
                        />

                        <a
                          className="d-inline-block black-link pb-2"
                          href={contactInformation?.phone2.number?.url}
                        >
                          {contactInformation?.phone2.number?.title}
                        </a>
                      </Col>
                    </Row>
                    <Row className="mb-4">
                      <Col>
                        <GatsbyImage
                          className=" d-inline-block me-3"
                          quality="100"
                          image={
                            contactInformation?.openingHours.image.node
                              ?.localFile.childImageSharp.gatsbyImageData
                          }
                          alt={
                            contactInformation?.openingHours.image.node?.altText
                          }
                          placeholder="blurred"
                          style={{ maxWidth: "30px" }}
                          formats={["auto", "webp"]}
                        />
                        <p className=" d-inline-block py-0 m-0">
                          <span>Monday - Saturday</span>{" "}
                          {data.wpOpeningTime.openingTimeFields.mondaySaturday}
                          <br />
                          <span>Sunday</span>{" "}
                          {data.wpOpeningTime.openingTimeFields.sunday}
                        </p>{" "}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <GatsbyImage
                          className=" d-inline-block me-3"
                          quality="100"
                          image={
                            contactInformation?.email.image.node?.localFile
                              .childImageSharp.gatsbyImageData
                          }
                          alt={contactInformation?.email.image.node?.altText}
                          placeholder="blurred"
                          style={{ maxWidth: "30px" }}
                          formats={["auto", "webp"]}
                        />

                        <a
                          className="d-inline-block black-link pb-3"
                          href={contactInformation?.email.email?.url}
                        >
                          {contactInformation?.email.email?.title}
                        </a>
                      </Col>
                    </Row>

                    {contactInformation?.socialLinks &&
                      contactInformation?.socialLinks.social.length > 0 && (
                        <>
                          <h3 className="fs-4 text-primary mt-4">
                            {contactInformation?.heading}
                          </h3>
                          {contactInformation.socialLinks.social.map((item) => (
                            <a
                              className="primary-link me-3 fs-3 d-inline-block  quicksand social-icon"
                              href={item?.url}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <img
                                src={item?.image.node?.mediaItemUrl}
                                style={{ width: "28px" }}
                              />
                            </a>
                          ))}
                        </>
                      )}
                  </Col>
                  <Col className="p-lg-5 p-3 " lg={7}>
                    <h2 className="text-primary mb-5">
                      {contactForm?.heading ?? "Send us a message"}
                    </h2>
                    <ContactForm buttonLabel={contactForm?.formBtnLabel} />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Reviews topPadding="pt-7" title={contactReviewsSection?.heading} />
    </Layout>
  );
};

export default ContactUsPage;
