import React from "react";
import { useForm, ValidationError } from "@formspree/react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Col, Row } from "react-bootstrap";

function ContactForm({ buttonLabel }) {
	const [state, handleSubmit] = useForm("mjvdvnjq");
	if (state.succeeded) {
		return (
			<div>
				{" "}
				<Form className="w-100" onSubmit={handleSubmit}>
					<Row>
						<Col lg={6}>
							<Form.Group className="mb-4" controlId="name">
								<Form.Label>Full Name</Form.Label>
								<Form.Control className="py-3" name="name" type="text" />
							</Form.Group>
							<ValidationError
								prefix="Name"
								field="name"
								errors={state.errors}
							/>
						</Col>
						<Col lg={6}>
							<Form.Group className="mb-4" controlId="telephone">
								<Form.Label>Phone Number</Form.Label>
								<Form.Control className="py-3" type="tel" name="telephone" />
							</Form.Group>

							<ValidationError
								prefix="Telephone"
								field="telephone"
								errors={state.errors}
							/>
						</Col>
					</Row>

					<Form.Group className="mb-4" controlId="email">
						<Form.Label>Email</Form.Label>
						<Form.Control className="py-3" name="_replyto" type="email" />
					</Form.Group>
					<ValidationError prefix="Email" field="email" errors={state.errors} />

					<Form.Group className="mb-4" controlId="message">
						<Form.Label>Message</Form.Label>
						<Form.Control name="message" as="textarea" rows={4} />
					</Form.Group>

					<ValidationError
						prefix="Message"
						field="message"
						errors={state.errors}
					/>

					{/* <div
				class="g-recaptcha"
				data-sitekey="6LcGzDIfAAAAADFRloAf1kbMqoVVlJerWOdiW2te"
			></div> */}
					<div className="">
						<Button
							style={{ borderRadius: "0px" }}
							variant="primary"
							className="btn btn-primary w-100 w-md-auto text-white px-5 py-2 mt-4"
							type="submit"
							id="contact-send-btn"
							disabled={state.submitting}
						>
							{buttonLabel ?? "Send"}
						</Button>
					</div>
				</Form>
				<div className="d-none">{(window.location = "/thank-you")}</div>
			</div>
		);
	}
	return (
		<Form className="w-100" onSubmit={handleSubmit}>
			<Row>
				<Col lg={6}>
					<Form.Group className="mb-4" controlId="name">
						<Form.Label>Full Name</Form.Label>
						<Form.Control className="py-3" name="name" type="text" />
					</Form.Group>
					<ValidationError prefix="Name" field="name" errors={state.errors} />
				</Col>
				<Col lg={6}>
					<Form.Group className="mb-4" controlId="telephone">
						<Form.Label>Phone Number</Form.Label>
						<Form.Control className="py-3" type="tel" name="telephone" />
					</Form.Group>

					<ValidationError
						prefix="Telephone"
						field="telephone"
						errors={state.errors}
					/>
				</Col>
			</Row>

			<Form.Group className="mb-4" controlId="email">
				<Form.Label>Email</Form.Label>
				<Form.Control className="py-3" name="_replyto" type="email" />
			</Form.Group>
			<ValidationError prefix="Email" field="email" errors={state.errors} />

			<Form.Group className="mb-4" controlId="message">
				<Form.Label>Message</Form.Label>
				<Form.Control name="message" as="textarea" rows={4} />
			</Form.Group>

			<ValidationError prefix="Message" field="message" errors={state.errors} />

			{/* <div
				class="g-recaptcha"
				data-sitekey="6LcGzDIfAAAAADFRloAf1kbMqoVVlJerWOdiW2te"
			></div> */}
			<div className="">
				<Button
					style={{ borderRadius: "0px" }}
					variant="primary"
					className="btn btn-primary w-100 w-md-auto text-white px-5 py-2 mt-4"
					type="submit"
					id="contact-send-btn"
					disabled={state.submitting}
				>
					Send
				</Button>
			</div>
		</Form>
	);
}
export default ContactForm;
